
import { BillingCycleEntity, EMPTY_BILLING_CYCLE_ENTITY } from "domain/entity/BillingCycle/BillingCycleEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { BillingCycleRepository } from "domain/repository/BillingCycle/BillingCycleRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { createBillingCycleMaintenanceValidationSchema } from "presentation/constant/BillingCycle/BillingCycleMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { BillingCycleMaintenanceModel } from "presentation/model/BillingCycle/BillingCycleMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface BillingCycleMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<BillingCycleMaintenanceModel>> | ((value: SetStateAction<BillingCycleMaintenanceModel>) => void),
    ],
    repo: BillingCycleRepository,
    parameterDetailRepo: ParameterDetailRepository,
}

export const BillingCycleMaintenanceVM = ({ dispatch, repo, parameterDetailRepo }: BillingCycleMaintenanceVMProps) => {
    const [billingCycleMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
            billingCycleList => {
                const billingCycleDropdownOption = billingCycleList?.map((billingCycle) => ({
                    dropdownLabel: billingCycle.parameterDtlCode,
                    tagLabel: billingCycle.parameterDtlCode,
                    value: billingCycle.parameterDtlCode,
                })) ?? []

                billingCycleMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingCycleTypeDropdownOptions: billingCycleDropdownOption
                    }
                }))
            }
        )
    }

    const onSearch = async () => {
        const entities = await repo.getEntities();

        billingCycleMainDispatch(prevState => ({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_BILLING_CYCLE_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (selecedRows: BillingCycleEntity[]) => {
        billingCycleMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        billingCycleMainDispatch(prevState => {

            return {
                ...prevState,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_BILLING_CYCLE_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: BillingCycleEntity) => {
        billingCycleMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: currentEntity.masterType === 'Country Code',
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onEditClick = (currentEntity: BillingCycleEntity) => {
        billingCycleMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: currentEntity.masterType === 'Country Code',
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = () => {
        billingCycleMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_BILLING_CYCLE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        billingCycleMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_BILLING_CYCLE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_BILLING_CYCLE_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
                isShowEditPanel: false,
            }
        })
    }

    const onSaveClicked = () => {
        billingCycleMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: BillingCycleEntity, isAdd: boolean) => {
        const valResult = isAdd ? await Validation(createBillingCycleMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            billingCycleMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        billingCycleMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === 'billingCycle') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        billingCycleMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        billingCycleMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }


    return {
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onEditClick: onEditClick,
    }
}